import React from "react"
import { Helmet } from "react-helmet"
import config from "../../site.config"

/** components */
import Layout from "../components/Layout"
import { theme } from "../components/Theme"

/** dynamic sections */
import HeroTopSection from "../components/dynamic-sections/HeroTopSection"
import RibbonSection from "../components/dynamic-sections/RibbonSection"
import HeroBottomSection from "../components/dynamic-sections/HeroBottomSection"
import AwardsSection from "../components/dynamic-sections/AwardsSection"
import HeroRightSection from "../components/dynamic-sections/HeroRightSection"
import ThreeBoxImageSection from "../components/dynamic-sections/ThreeBoxImageSection"

/** color theme */
const colorPalette = theme.colorPalettes.blue

/** svg */
import NorhartIcon from "../../assets/norhart-icon.svg"
import NorhartDarkCircleIcon from "../../assets/norhart-dark-circle-icon.svg"

/** props */
interface Props {
  data: any
}

/** const */
const CovidPage: React.FC<Props> = (props) => {
  const ldJson = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        item: {
          "@id": "/covid/",
          name: "COVID-19 | Norhart",
          image: `${config.siteMetadata.siteUrl}/covid/covid-hero-open-graph.png`,
        },
      },
    ],
  }

  return (
    <Layout
      title="COVID-19 | Norhart"
      description="Norhart is helping prevent the spread of COVID-19. At Norhart, the health, safety, and wellbeing of our employees, apartment residents, and loved ones remain our top priority."
      keywords="norhart, luxury, innovative, smart, technology, apartment, apartments, building, buildings, mn, minnesota, minneapolis, st paul, rent, rentals, resident, residents, forest lake, blaine, circle pines, lexington, coon rapids, Oakdale, encore apartments, lexington lofts apartments, Oakdale apartments, gateway green apartments, mill pond apartments, birchview apartments, legacy pointe apartments, heritage heights apartments, springwood apartments, greystone apartments, northbrook apartments, construction, norhart construction, lean construction"
      imageTwitter={`${config.siteMetadata.siteUrl}/covid/norhart-covid-hero-twitter-card.png`}
      imageOpenGraph={`${config.siteMetadata.siteUrl}/covid/norhart-covid-hero-open-graph.png`}
      colorPalette={colorPalette}
    >
      <HeroTopSection
        title="COVID-19"
        subtitle="Help Prevent The Spread"
        imageTitle="Norhart Support"
        image="/covid/norhart-covid-hero.png"
        colorPalette={colorPalette}
      />

      <RibbonSection
        title="Norhart COVID Response"
        tagLine="Helping people is our #1 priority"
        colorPalette={colorPalette}
      />

      <Helmet>
        <script type="application/ld+json">{JSON.stringify(ldJson)}</script>
      </Helmet>

      <HeroBottomSection
        backgroundColorOne={colorPalette.cardAccentTop}
        backgroundColorTwo={colorPalette.cardAccentTop}
        icon={NorhartDarkCircleIcon}
        iconTitle="Covid-19"
        title="Health, Safety, and Wellbeing"
        subTitle="As Norhart responds to COVID-19, the health, safety, and wellbeing of our employees, residents, and loved ones remain our top priority."
        image="/covid/norhart-team-mask-up.png"
        imageAlt="Norhart Support Team"
        textColor={colorPalette.textColor}
        asoEffect="fade-in"
      />

      <ThreeBoxImageSection
        backgroundColorOne="#EFF2F4"
        backgroundColorTwo="#EFF2F4"
        boxDescriptionOne="Authorized COVID-19 vaccines can help protect you from COVID-19."
        boxDescriptionThree="Stay 6 feet away from others and avoid close contact with people who are sick."
        boxDescriptionTwo="Vaccinated or not, wear a mask to prevent possibly spreading the virus."
        boxImageOne="/covid/get-vaccinated.png"
        boxImageThree="/covid/social-distance.png"
        boxImageTwo="/covid/mask-up.png"
        boxSubTitleOne="Get Vaccinated"
        boxSubTitleThree="Social Distancing"
        boxSubTitleTwo="Mask Up"
        boxTitleOne="COVID-19"
        boxTitleThree="COVID-19"
        boxTitleTwo="COVID-19"
        colorPalette={colorPalette}
        icon={NorhartDarkCircleIcon}
        iconTitle="COVID Guidelines"
        subtitle="COVID-19 vaccines are effective at keeping you from getting COVID-19, especially severe illness and death. Vaccines are safe, effective, and free! #StaySafeMN"
        title="Prevent Getting Sick"
      />

      <HeroRightSection
        backgroundColorOne={colorPalette.heroTop}
        backgroundColorTwo={colorPalette.heroBottom}
        fontTextColor="#FFFFFF"
        titleTopLeft="CDC Saving Lives"
        badgeTitle="COVID-19"
        title="CDC Guidelines"
        subtitle="Find links to guidance and information on all topics related to COVID-19."
        imageTitle="Norhart Website Pingdom Uptime Report"
        image="/covid/norhart-cdc-covid19-website.webp"
        buttonName="Learn More"
        buttonUrl="https://www.cdc.gov/coronavirus/"
        colorPalette={colorPalette}
        colSizeOne={4}
        colSizeTwo={8}
        bottomMargin={0}
        asoEffect="fade"
      />

      <AwardsSection
        header="Norhart Apartments"
        title="Health and Safety First"
        subtitle="We invite you to tour our Forest Lake and Blaine, Minnesota apartments while staying safe and healthy in the process. #MakingPeoplesLivesBetter"
        colorPalette={colorPalette}
      />
    </Layout>
  )
}

/** export */
/** export */
export default CovidPage
